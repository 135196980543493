.table-footer-wrapper td {
  vertical-align: bottom;
}

.table-footer-content {
  display: flex;
  align-items: center;
  gap: 22px;
  justify-content: flex-end;
  padding-bottom: 28px;
  padding-right: 39px;
  padding-top: 20px;

  position: absolute;
  bottom: 0px;
  right: 0px;
}

.table-footer-content p {
  color: var(--secondary-grey-color);
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0px;
}

.table-footer-content button {
  border-radius: 4px;
  padding: 6px 9px;
}

.table-footer-content button:hover {
  background: #00000033;
}

.table-footer-content button:active {
  background: #00000066;
}

.table-footer-content button:first-child {
  margin-right: 24px;
}

@media screen and (max-width: 1199px) {
  .table-footer-content {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 599px) {
  .table-footer-wrapper {
    right: 24px;
    bottom: 18px;
  }

  .table-footer-content {
    gap: 21px;
    padding-top: 15px;
  }

  .table-footer-content button:first-child {
    margin-right: 26px;
  }
}
