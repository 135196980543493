.card-content-wrapper {
  background: var(--navy-blue-color);
}

.cards-border-radius {
  border-radius: 8px;
}

.dashboard-wrapper {
  padding: 32px 24px 42px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.last-update-time {
  color: #ffffff4d;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: end;
  padding-bottom: 3px;
  padding-right: 32px;
}

.analytics-table-row .MuiSkeleton-root {
  background: #e0e0e01a;
  border-radius: 4px;
  height: 12px;
}

.analytics-table-row td {
  border-bottom: 1px solid #e0e0e00f;
  color: #e0e0e0b3;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  padding: 0px 19px;
  height: 46px;
  text-align: left;
  white-space: pre;
}

.analytics-table-row td,
.analytics-table-row td div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.analytics-table-row:hover td {
  color: #ffffffb3;
}

.tooltip-element {
  cursor: pointer;
  font-weight: 600;
}

.tooltip-element:hover {
  color: #ffffff;
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: 599px) {
  .dashboard-wrapper {
    padding: 76px 7px 42px;
  }

  .analytics-wrapper .MuiTableContainer-root {
    max-height: 430px !important;
    height: 380px !important;
  }

  .last-update-time {
    /* padding-bottom: 12px; */
    padding-right: 12px;
  }

  .analytics-table-row td {
    font-size: 10px;
    line-height: 12px;
    padding: 0px 8px;
  }

  .analytics-table-row > td:first-child {
    padding-left: 0px;
  }
}
