.content-list-item {
  display: block;
}

.content-list-item .MuiButtonBase-root {
  min-height: 40px;
  padding: 18px 30px;
}

@media (max-width:800px){
  .content-list-item .MuiButtonBase-root {
    padding: 12px 20px;
  }
}

.content-list-item .MuiListItemText-root .MuiTypography-root {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #757575;
}

.content-list-item .MuiListItemIcon-root {
  min-width: 0px;
  justify-content: center;
}

.menu-list-item:hover {
  background-color: rgba(224, 224, 224, 0.07) !important;
}

.menu-list-item:hover svg {
  color: rgba(224, 224, 224, 0.07) !important;
}

.active-page path {
  stroke: #e0e0e0;
}

.inactive-page path {
  stroke: #757575;
}

.content-username .MuiListItemText-root .MuiTypography-root,
.active-page .MuiListItemText-root .MuiTypography-root {
  color: #e0e0e0;
}

.inactive-page .MuiListItemText-root .MuiTypography-root {
  color: #757575;
}
