.active-users .card-counts-wrapper {
  min-height: 0px;
}

.active-users-wrapper {
  padding: 24px 34px 48px;
}

.users-count-wrapper h2,
.users-count-wrapper p,
.progress-count-wrapper span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  margin: 0px;
}

.users-count-wrapper h2 {
  color: var(--secondary-grey-color);
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}

.users-count-wrapper p {
  color: var(--dashboard-grey-color);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.active-countries-wrapper {
  height: 258px;
  margin-top: 31px;
  overflow: auto;
}

.country-wrapper:first-child {
  margin-top: 0px;
}

.country-wrapper {
  margin-top: 23px;
  width: 90%;
}

.active-country-name {
  color: var(--secondary-grey-color);
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}

.progress-count-wrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.progress-count-wrapper .MuiLinearProgress-root {
  background: #2d323e;
  border-radius: 8px;
  height: 5px;
  width: 100%;
}

.progress-count-wrapper .MuiLinearProgress-bar {
  background: #d9d9d9;
  border-radius: 8px;
}

.progress-count-wrapper span:last-child {
  color: var(--secondary-grey-color);
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}

.country-name-tooltip {
  margin-bottom: 6px !important;
}

.country-name-tooltip p {
  line-height: inherit;
  text-decoration: none;
}

.jvectormap-container svg {
  height: 100%;
  width: 100%;
}

.active-countries-wrapper::-webkit-scrollbar {
  width: 6px;
}

.active-countries-wrapper::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
}

.active-countries-wrapper::-webkit-scrollbar-thumb {
  background: #0f0f0f;
  border-radius: 10px;
}

.active-countries-wrapper::-webkit-scrollbar-thumb:hover {
  background: #0f0f0f;
}

@media screen and (max-width: 899px) {
  .active-countries-wrapper {
    height: 250px;
  }
}

@media screen and (max-width: 599px) {
  .active-countries-wrapper {
    height: 280px;
  }
}
