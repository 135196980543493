.loading-screen-wrapper {
  background: #0f0f0f;
  position: absolute;
  inset: 0;
  mix-blend-mode: multiply;
  opacity: 0.7;
  filter: blur(8px);
  z-index: 999999;
}

.spinner-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
}
