@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

:root {
  --primary-grey-color: #757575;
  --secondary-grey-color: #e0e0e0;
  --navy-blue-color: #1b2130;
  --error-color: #cd1c35;
  --cobalt-blue-color: #10141d;
  --secondary-neon-color: #ff4a4a;
  --dashboard-grey-color: #5f646e;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  background-color: #0f0f0f !important;
  scroll-behavior: smooth;
  height: 100%;
}

div#root {
  height: 100%;
}

.react-grid-item {
  transition: none !important;
}

.h-table-full,
.h-table-full table {
  height: 100%;
}

.table-h-97 .MuiTableContainer-root,
.table-h-97 table {
  /* height: 97%; */
}

@media screen and (min-width: 1200px) and (max-width: 1536px) {
  .static-layout-wrapper .react-grid-layout .react-grid-item {
    transform: translate(4px, 0px) !important;
  }
}

@media screen and (min-width: 1537px) {
  .static-layout-wrapper .react-grid-layout .react-grid-item {
    transform: unset !important;
  }
}
