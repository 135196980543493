.block {
  display: flex;
  justify-content: center;
}

.navbar {
  background: #0f0f0f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding-left: 24px;
  padding-right: 24px;
  position: absolute;
  left: 0px;
  z-index: 2;
  width: -webkit-fill-available;
  width: -moz-available;
}

.navbar_wrapper {
  height: 100%;
  background: #0f0f0f;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 22px;
  padding-bottom: 24px;
}

.close_icon {
  position: absolute;
  top: 48px;
  right: 24px;
}

.drawer > div:nth-child(3) {
  width: 100%;
}

.content_wrapper_xs {
  padding: 0 24px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.content_wrapper_xs .nav_link_wrapper:last-child {
  border-bottom: none;
}

.icons_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 10px;
  width: 100%;
}

.icons_wrapper img:first-child,
.frame {
  height: 30px;
  width: 103px;
}

.nav_link_wrapper {
  border-bottom: 1px solid var(--primary-grey-color);
  display: flex;
  align-items: center;
  gap: 16.6px;
}

.nav_link_wrapper svg {
  height: 24px;
  width: 24px;
}

.nav_link_wrapper path {
  stroke: #e0e0e0;
}

.nav_link {
  background: none;
  color: #e0e0e0;
  display: block;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 18px 0px;
  text-decoration: none;
  width: 100%;
}

.nav_link:hover {
  color: red;
}

.username_wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  padding-left: 24px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.user_name {
  color: #e0e0e0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}
