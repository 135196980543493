.table-wrapper {
  background-color: transparent !important;
  border-radius: 8px !important;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  height: -webkit-fill-available;
  overflow: hidden;
  padding: 28px 6px 30px;
}

.table-wrapper tbody tr:hover {
  background: var(--navy-blue-color);
}

.table-wrapper .selected-row {
  background: var(--cobalt-blue-color) !important;
}

.table-wrapper ::-webkit-scrollbar,
.top-tracks-wrapper ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  /* background: #ffffff33; */
  background: transparent;
  border-radius: 4px;
}

.table-wrapper ::-webkit-scrollbar-track,
.top-tracks-wrapper ::-webkit-scrollbar-track {
  border-radius: 4px;
}

.table-wrapper ::-webkit-scrollbar-thumb,
.top-tracks-wrapper ::-webkit-scrollbar-thumb {
  margin: 2px 0px;
  background: #ffffff99;
  border-radius: 4px;
}

.table-wrapper ::-webkit-scrollbar-corner,
.top-tracks-wrapper ::-webkit-scrollbar-corner {
  background: transparent;
}

.table-container-wrapper {
  height: -webkit-fill-available;
  height: -moz-available;
  padding: 14px 0px;
}

.table-container-wrapper thead > tr > th:first-child,
.table-container-wrapper tbody > tr > td:first-child {
  padding-left: 36px;
}

.table-heading-name {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0px;
  /* padding-bottom: 20px; */
}

.heading-cell {
  background: var(--navy-blue-color) !important;
  color: #ffffff4d !important;
  border-bottom: none !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14px !important;
  white-space: pre;
}

.sort-heading-cell:hover,
.sort-heading-cell span:hover {
  color: #ffffffb3 !important;
}

.sort-heading-cell .Mui-active,
.sort-heading-cell .MuiTableSortLabel-icon {
  color: #ffffff !important;
}

@media screen and (max-width: 1199px) {
  .table-input-field {
    align-items: flex-start !important;
    flex-direction: column;
    gap: 20px;
  }

  .table-input-field .MuiFormControl-root {
    width: 100%;
  }
}

@media screen and (max-width: 599px) {
  .table-wrapper {
    padding: 12px 0px 12px;
    height: -webkit-fill-available;
  }

  .table-container-wrapper {
    /* padding: 8px 0px 16px; */
    padding: 8px 20px 16px;
  }

  .table-container-wrapper thead > tr > th:first-child,
  .table-container-wrapper tbody > tr > td:first-child {
    /* padding-left: 22px; */
    padding-left: unset;
  }

  .table-container-wrapper tbody tr:last-child td {
    border-bottom: none;
  }

  .table-heading-name {
    font-size: 16px;
    line-height: 20px;
  }

  .heading-cell {
    font-size: 9px !important;
    font-weight: 400 !important;
    line-height: 10px !important;
  }
}
