.card-wrapper {
  border-radius: 8px;
  height: -webkit-fill-available;
  padding: 28px 6px 30px;
}

.card-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 20px;
}

.card-heading {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0px;
}

.card-filters-wrapper {
  background: #1b213026;
  border-radius: 4px;
  padding: 4px 6px;
}

.card-filters-wrapper button {
  box-shadow: 0px 1px 2px 0px #0000000d;
  border-radius: 6px;
  color: var(--primary-grey-color);
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  margin-right: 6px;
  padding: 4px 6px;
  min-width: 42px;
}

.card-filters-wrapper button:last-child {
  margin-right: 0px;
}

.card-filters-wrapper button:hover {
  background: #161a26;
  box-shadow: 0px 1px 2px 0px #0000000d;
}

.card-filters-wrapper button.active-tab {
  background-color: var(--navy-blue-color);
  box-shadow: 0px 1px 2px 0px #0000000d;
  color: #ffffff99;
  font-weight: 500;
}

.card-filters-wrapper button > span {
  display: none;
}

.card-counts-wrapper {
  display: flex;
  width: -webkit-fill-available;
  width: -moz-available;
}

.top-tracks-heading {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 23px 38px 23px 40px;
  width: -webkit-fill-available;
}

.top-tracks-heading.count-title {
  width: 60%;
  text-align: left;
  white-space: pre;
}

.top-tracks-heading:hover {
  background: transparent !important;
}

.top-tracks-button {
  align-items: center;
  background: linear-gradient(268.92deg, #ff1a1a 14.96%, #fd8a50 100%);
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400 !important;
  height: 40px;
  width: 107px;
}

.top-tracks-button::before {
  background-color: var(--navy-blue-color);
  border-radius: 4px;
}

.chart-wrapper {
  padding: 26px 30px 45px;
}

.metrics-chart-wrapper {
  padding-left: 24px;
  padding-right: 16px;
}

.devices-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding: 11px 52px 0px;
  margin-bottom: -11px;
  height: 12px;
}

.device-content {
  display: flex;
  align-items: center;
  gap: 6px;
}

.device-content span {
  color: var(--primary-grey-color);
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
}

.y-axis-labels {
  width: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .top-tracks-heading {
    padding-left: 26px;
    padding-right: 26px;
  }
}

@media screen and (min-width: 600px) {
  .recognition-wrapper .count-wrapper {
    padding-top: 22px;
    padding-bottom: 22px;
  }
}

@media screen and (max-width: 599px) {
  .card-wrapper {
    padding: 16px 0px;
  }

  .card-header-wrapper {
    padding: 0px 4px 14px;
  }

  .card-heading {
    font-size: 16px;
    line-height: 20px;
  }

  .card-filters-wrapper button {
    min-width: unset;
    padding: 4px 11px;
  }

  .chart-wrapper {
    padding: 24px 16px 26px;
  }

  .metrics-chart-wrapper {
    padding-left: 16px;
    padding-right: 10px;
  }

  .chart-wrapper canvas {
    height: 242px !important;
  }

  .recognition-chart canvas {
    height: 278px !important;
  }

  .top-tracks-heading {
    padding: 20px 17px;
  }

  .top-tracks-button {
    font-size: 10px !important;
    line-height: 11.72px !important;
    width: 80px;
    height: 35px;
  }

  .devices-wrapper {
    padding: 0 24px;
  }
}
