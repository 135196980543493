.button-text {
  border: none;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  text-transform: capitalize !important;
  position: relative;
}

.button-overlay {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

button:disabled {
  opacity: 0.5;
}

.contained-button,
.outlined-button {
  background: linear-gradient(268.92deg, #ff1a1a 19.96%, #fd8a50 100%);
}

.contained-button:not(:disabled):hover {
  background: linear-gradient(268.92deg, #ff3838 19.96%, #fd9560 100%);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
}

.contained-button:not(:disabled):active .button-overlay,
.contained-button:not(:disabled):focus .button-overlay {
  box-shadow: 0px 6px 6px 5px rgba(0, 0, 0, 0.25);
}

.contained-button:not(:disabled):focus .button-overlay {
  background: rgba(0, 0, 0, 0.05);
}

.contained-button:not(:disabled):active .button-overlay {
  background: rgba(0, 0, 0, 0.15);
}

.outlined-button {
  z-index: 1;
}

.outlined-button::before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border-radius: 8px;
  background-color: #0f0f0f;
  z-index: -1;
}

.outlined-button::after {
  content: "";
  font-size: 16px;
  background: linear-gradient(to left, #00ffa3, #dc1fff);
  -webkit-background-clip: text;
  color: transparent;
}

.outlined-button:not(:disabled):hover .button-overlay {
  background: rgba(158, 158, 158, 0.05);
  opacity: 0.8;
}

.outlined-button:not(:disabled):focus .button-overlay {
  background: rgba(158, 158, 158, 0.1);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.outlined-button:not(:disabled):active .button-overlay {
  background: rgba(158, 158, 158, 0.15);
}

.outlined-button:not(:disabled):hover .button-overlay,
.outlined-button:not(:disabled):active .button-overlay {
  box-shadow: 0px 4px 4px 5px rgba(0, 0, 0, 0.1);
}

.secondary-button {
  background: transparent;
  padding: 14px 33px;
}

.secondary-button:not(:disabled):hover {
  background: rgba(158, 158, 158, 0.05);
  box-shadow: 0px 4px 4px 5px rgba(0, 0, 0, 0.1);
}

.secondary-button:not(:disabled):focus .button-overlay {
  background: rgba(158, 158, 158, 0.1);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.secondary-button:not(:disabled):active .button-overlay {
  background: rgba(158, 158, 158, 0.15);
  box-shadow: 0px 4px 4px 5px rgba(0, 0, 0, 0.1);
}

button:focus-visible {
  outline: none !important;
}
