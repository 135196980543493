.account-types-wrapper {
  padding-top: 24px !important;
  width: 100%;
}

.account-types-wrapper .MuiButtonBase-root {
  color: var(--secondary-grey-color);
  padding: 0px;
}

.account-types-wrapper .MuiFormGroup-root {
  gap: 10px;
}

.account-types-wrapper .Mui-checked {
  color: #ff1a1a !important;
}

.account-types-wrapper .MuiFormControlLabel-root {
  margin-right: 0px;
  padding: 11px 20px;
  width: 320px;
}

.selected-type {
  background: #00000066;
}

.account-types-wrapper .MuiFormControlLabel-root {
  border-radius: 8px;
}

.account-types-wrapper .MuiFormControlLabel-root:hover {
  background: #00000033;
}

.account-types-wrapper .MuiTypography-root {
  color: #ffffffb3;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 16px;
  width: 100%;
}

.account-types-wrapper .MuiTypography-root > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 599px) {
  .account-types-wrapper .MuiFormControlLabel-root {
    padding: 11px 16px;
    width: unset;
  }

  .account-types-wrapper .MuiFormGroup-root {
    gap: 6px;
  }

  .account-types-wrapper .MuiTypography-root {
    font-size: 12px;
    line-height: 16px;
  }

  .modal-type-icon {
    max-width: 20px;
    max-height: 20px;
  }
}
