.loading-spinner {
  width: 123px;
  height: 123px;
  border-radius: 50%;
  border: 10px solid #4e4e4e;
  border-right-color: var(--secondary-grey-color);
  animation: rotate 1.5s infinite linear;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
