.admin-header-wrapper {
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
  width: -moz-available;
  position: absolute;
  padding: 28px 27px 24px 40px;
  z-index: 10;
}

.admin-logo-wrapper {
  max-width: 131.35px;
}

.admin-logo-wrapper img {
  cursor: pointer;
  max-width: 100%;
  max-height: 38.16px;
}

.header-avatar-wrapper {
  display: flex;
  align-items: center;
  margin-right: 27px;
}

.header-avatar {
  height: 32px !important;
  width: 32px !important;
  /* margin-right: 16px; */
}

.header-username {
  color: #ffffff !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 16px !important;
  letter-spacing: 0px !important;
}

.dot-icon-btn {
  color: #ffffff !important;
  padding: 0px !important;
}

.justify-dashboard-header {
  justify-content: space-between;
}

.justify-login-header {
  justify-content: space-between;
}

@media (max-width: 899px) {
  .justify-login-header {
    justify-content: center;
  }

  .justify-login-header > div {
    text-align: center;
  }

  .admin-header-wrapper {
    padding: 18px 12px 8px 16px;
  }

  .admin-dashboard-wrapper {
    justify-content: space-between;
  }

  .admin-logo-wrapper img {
    height: 30px;
  }

  .header-avatar-wrapper {
    margin-right: 0px;
  }

  .header-avatar {
    height: 24px !important;
    width: 24px !important;
    margin-right: 11px;
  }

  .header-username {
    font-size: 12px !important;
  }

  #header-menu-appbar > div:nth-child(3) {
    background: #131722;
    border-radius: 8px;
    width: 148px;
  }

  #header-menu-appbar > div:nth-child(3) li {
    justify-content: center !important;
  }
}
