.count-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 9.67px;
  padding: 12px 40px;
  text-align: center;
  width: -webkit-fill-available;
  width: -moz-available;
}

.count-wrapper:hover {
  background: #00000033;
}

.count-wrapper > img:first-child {
  display: flex;
  align-self: baseline;
}

.count-title {
  color: #ffffff4d;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin: 0px 0px 6px;
  white-space: pre;
}

.count-wrapper h1 {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  margin: 0px;
  text-align: left;
}

.card-counts-wrapper > div:first-child.active-metrics {
  border-top-left-radius: 8px;
}

.card-counts-wrapper > div:last-child.active-metrics {
  border-top-right-radius: 8px;
}

.active-metrics {
  background: #00000066;
}

.trend-section {
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.trend-text {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 3px;
}

.trend-text span {
  margin-right: 2.5px;
}

.trend-high {
  color: #2ac670;
}

.trend-low {
  color: #b31037;
}

.trend-zero {
  color: #fdad15;
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .count-wrapper {
    padding: 20px 26px;
  }
}

@media screen and (max-width: 599px) {
  .count-wrapper {
    padding: 13px 17px;
  }

  .count-title {
    font-size: 9px;
    line-height: 10px;
    white-space: pre;
  }

  .count-wrapper > img:first-child {
    width: 16px;
    height: 16px;
  }

  .count-wrapper h1 {
    font-size: 16px;
    line-height: 16px;
  }

  .trend-section {
    margin-top: 4px;
  }
}
