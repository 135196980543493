.table-attributes {
  display: flex !important;
  align-items: center;
  gap: 10px;
  position: relative;
}

.table-attributes .tooltip-element {
  background: #2d323e;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  line-height: 16px;
  padding: 4px 8px;
}

.show-attributes {
  background: #2d323e;
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.show-attributes p {
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff99;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  text-decoration: underline;
  margin: 0px;
}

.attributes-spacing p {
  line-height: 18px;
}

.MuiTooltip-tooltip {
  padding: 0px !important;
}
