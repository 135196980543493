.vms-status {
  display: flex;
  text-transform: capitalize;
}

.vms-status span {
  display: block;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  margin-right: 10px;
}

.status-active {
  background: #009e10;
}

.status-bar:hover .status-active {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    #009e10;
}

.status-inactive {
  background: #9722F3;
}

.status-bar:hover .status-inactive {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    #9722F3;
}

.status-power-save-mode {
  background: #fd8850;
}

.status-bar:hover .status-power-save-mode {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    #fd8850;
}

.status-offline {
  background: #b31037;
}

.status-bar:hover .status-offline {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    #b31037;
}

.status-no-data {
  background: var(--primary-grey-color);
}

.status-bar:hover .status-no-data {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    var(--primary-grey-color);
}

.status-bar {
  border-radius: 2px;
  height: 18px;
  width: 5px;
}

.vms-wrapper table tfoot {
  height: 100%;
}

.vms-wrapper table tfoot tr td {
  vertical-align: bottom;
}

.vms-wrapper thead > tr > th:first-child,
.vms-wrapper tbody > tr > td:first-child {
  padding-left: 60px;
}

.vms-wrapper thead > tr > th:last-child,
.vms-wrapper tbody > tr > td:last-child {
  padding-right: 49px;
}

.vms-row td {
  cursor: pointer;
}

@media screen and (max-width: 599px) {
  .vms-status span {
    margin-right: 8px;
    height: 10px;
    width: 10px;
  }

  .status-bar {
    border-radius: 2px;
    height: 14px;
    width: 4px;
  }

  .vms-wrapper thead > tr > th:first-child,
  .vms-wrapper tbody > tr > td:first-child {
    padding-left: 14px;
  }

  .vms-wrapper thead > tr > th:last-child,
  .vms-wrapper tbody > tr > td:last-child {
    padding-right: 16px;
  }

  .vms-wrapper tbody tr > td,
  .vms-wrapper thead > tr > th span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .vms-wrapper tbody tr > td:first-child {
    width: 80px;
    max-width: 80px;
  }

  .vms-wrapper tbody tr > td:nth-child(2) {
    width: 80px;
    max-width: 80px;
  }

  .vms-wrapper tbody tr > td:nth-child(3) {
    width: 80px;
    max-width: 80px;
  }

  .vms-wrapper tbody tr > td:nth-child(4) {
    width: 80px;
    max-width: 80px;
  }

  .vms-arrow-right {
    height: 10px;
    width: 10px;
  }
}
