/* .venue-table-wrapper {
  overflow: visible;
} */

.venue-filters-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.venue-filters-wrapper > div:first-child {
  margin-right: 12px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.venue-name-wrapper {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.venue-name {
  cursor: pointer;
  color: #e0e0e080;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 10px;

  text-decoration: none;
}

@media screen and (max-width: 1199px) {
  .venue-filters-wrapper {
    align-items: flex-end;
  }
}

@media screen and (max-width: 599px) {
  .venue-filters-wrapper {
    flex-direction: column;
  }

  .venue-filters-wrapper > div:first-child {
    margin-right: 0px;
    padding-bottom: 12px;
  }

  .venue-name-wrapper {
    top: -5px;
  }

  .venue-name-wrapper * {
    margin-bottom: 0px;
  }
}
