.date-picker-wrapper {
  text-align: end;
  padding-bottom: 20px;
}

.dashboard-date-picker > div:first-child {
  background: var(--navy-blue-color);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  justify-content: center;
  padding: 9px 10px;
  width: 274px;
}

.dashboard-date-picker .react-daterange-picker__inputGroup,
.dashboard-date-picker .react-daterange-picker__range-divider {
  display: none;
  min-width: unset;
}

.dashboard-date-picker .react-daterange-picker__button {
  padding: 0px;
  width: 100%;
}

.dashboard-date-picker .react-daterange-picker__button label {
  cursor: pointer;
}

.dashboard-date-picker .date-wrapper p {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0px;
  width: 95px;
}

.dashboard-date-picker .date-wrapper p:first-child {
  text-align: right;
}

.dashboard-date-picker svg {
  stroke: var(--primary-grey-color);
}

.dashboard-date-picker .react-calendar {
  max-width: 464px;
  background: var(--navy-blue-color);
  box-shadow: 3px 4px 69px rgb(0 0 0 / 50%);
  border: none;
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  padding: 30px 30px 82px;
  width: auto;
}

.dashboard-date-picker .react-calendar {
  position: relative;
}

.dashboard-date-picker .react-calendar__navigation button {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
}

.dashboard-date-picker .react-calendar__navigation button:disabled {
  background: var(--navy-blue-color);
}

.dashboard-date-picker .react-calendar__navigation button:first-child,
.dashboard-date-picker .react-calendar__navigation button:last-child {
  display: none;
}

.dashboard-date-picker .search-calendar-btn {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 30px;
  bottom: 24px;
}

.dashboard-date-picker .search-calendar-btn::before {
  background-color: var(--navy-blue-color);
}

.dashboard-date-picker .react-calendar__navigation button:last-child::before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border-radius: 4px;
  background-color: var(--navy-blue-color);
  z-index: -1;
}

.dashboard-date-picker .react-calendar__navigation button:last-child::after {
  content: "";
  font-size: 16px;
  background: linear-gradient(to left, #00ffa3, #dc1fff);
  -webkit-background-clip: text;
  color: transparent;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(3) {
  flex-grow: inherit !important;
  pointer-events: none !important;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(2),
.dashboard-date-picker .react-calendar__navigation button:nth-child(4) {
  padding: 0px;
  width: 24px;
  border-radius: 50%;
  min-width: unset;
  position: absolute;
  top: 40px;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(2) {
  right: 60px;
  padding-right: 2px;
}

.dashboard-date-picker .react-calendar__navigation button:nth-child(4) {
  right: 33px;
  padding-left: 2px;
}

.dashboard-date-picker .react-calendar__month-view__weekdays {
  color: var(--secondary-grey-color);
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: capitalize;
}

.dashboard-date-picker .react-calendar__month-view__weekdays abbr {
  text-decoration: none;
}

.dashboard-date-picker .react-calendar__tile {
  color: var(--secondary-grey-color);
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  padding: 19px;
}

.dashboard-date-picker .react-calendar__tile:disabled {
  background: var(--navy-blue-color);
}

.dashboard-date-picker .react-calendar__tile--rangeStart,
.dashboard-date-picker .react-calendar__tile--rangeEnd,
.dashboard-date-picker .react-calendar__tile--hasActive,
.dashboard-date-picker .react-calendar__tile:enabled:hover,
.dashboard-date-picker .react-calendar__tile:enabled:focus {
  background: var(--secondary-neon-color) !important;
  border-radius: 50%;
}

.dashboard-date-picker .react-calendar__tile--active,
.dashboard-date-picker
  .react-calendar--selectRange
  .react-calendar__tile--hover {
  background: #ff4a4a33;
}

.dashboard-date-picker .react-calendar__tile--now {
  background: transparent;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}

.react-calendar__navigation button:enabled:hover {
  background-color: #ff4a4a33;
}

.date-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rotate-icon {
  transform: rotate(180deg);
  transition: 0.3s ease-in-out;
}

.calendar-arrow-icon {
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;
}

.calendar-custom-wrapper {
  position: absolute;
  top: 64px !important;
  z-index: 9;
  height: max-content !important;
  right: 10px !important;
  left: auto !important;
}

@media screen and (max-width: 599px) {
  .date-picker-wrapper {
    width: 100%;
  }

  .dashboard-date-picker {
    width: 100%;
    text-align: center;
  }

  .dashboard-date-picker .date-wrapper p {
    width: unset;
  }

  .dashboard-date-picker > div:first-child {
    justify-content: center;
    width: 100%;
    padding: 12px 10px;
  }

  .dashboard-date-picker .react-daterange-picker__inputGroup {
    flex-grow: initial;
  }

  .dashboard-date-picker svg {
    width: 15px;
    height: 15px;
  }

  .date-wrapper {
    justify-content: center;
    gap: 13px;
  }

  #calender-icon {
    position: fixed;
    right: 11px;
  }
}
