.login-bg-wrapper {
  position: relative;
}

.login-bg-wrapper img {
  height: 99vh;
  width: -webkit-fill-available;
  max-width: 100%;
}

.login-blur-gradient {
  background: linear-gradient(270deg, #0f0f0f 0%, rgba(15, 15, 15, 0) 100%);
  height: 100vh;
  width: 204px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.login-wrapper > div:first-child {
  min-width: 327px;
}

.login-heading {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0px;
  margin: 0px;
}

.login-inputs-wrapper {
  padding-top: 36px;
}

.login-password-wrapper {
  padding-top: 24px;
}

.login-input-label {
  color: var(--secondary-grey-color) !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  letter-spacing: 0px !important;
  margin-bottom: 16px;
}

.login-btn-wrapper {
  padding: 24px 0px 40px;
}

.login-button {
  padding: 14px 16px 15px !important;
}

.or-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.or-text-wrapper hr {
  margin: 0px;
  width: 42%;
  border: none;
  height: 1px;
  background-color: var(--primary-grey-color);
}

.or-text-wrapper span {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0px;
}

.continue-btns-wrapper {
  padding: 40px 0px 0px;
}

.continue-btns-wrapper div:first-child {
  margin-bottom: 16px;
}

.continue-btn button,
.login-button {
  width: 100%;
}

.continue-btn button {
  padding: 12px 16px !important;
  font-size: 12px !important;
  text-transform: inherit !important;
}

.continue-btn button > img {
  margin-right: 10px;
}

.continue-btn .Mui-disabled {
  opacity: 0.5;
}

.login-inputs-wrapper input:-webkit-autofill {
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #0f0f0f inset;
  transition: background-color 5000s ease-in-out 0s;
}

.message-alert > div > div:first-child,
.error-alert > div > div:first-child {
  box-shadow: 0px 4px 4px 4px #00000040;
  background: #131722;
  border-radius: 8px;
  justify-content: center;
  padding: 18px;
  width: 70vw;
}

.message-alert > div > div:first-child > div,
.error-alert > div > div:first-child > div {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  opacity: 0.7;
  padding: 0px;
}

.message-alert > div > div:first-child {
  width: auto;
  justify-content: left;
}

.error-message {
  color: #ffffff99 !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  line-height: 12px !important;
  letter-spacing: 0px !important;
  padding-top: 12px;
  height: 12px;
}

.visible-text {
  visibility: hidden;
}

@media screen and (max-width: 899px) {
  .login-bg-wrapper img {
    height: auto;
  }

  .login-blur-gradient {
    background: linear-gradient(0deg, #0f0f0f 0%, rgba(15, 15, 15, 0) 100%);
    width: 100%;
    height: 87px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: unset;
  }

  .login-wrapper {
    height: auto;
    padding: 10px 24px;
  }

  .login-wrapper > div:first-child {
    min-width: auto;
    width: 100%;
  }

  .login-heading {
    font-size: 24px;
    line-height: 32px;
  }

  .login-inputs-wrapper {
    padding-top: 18px;
  }

  .login-input-label {
    font-size: 12px !important;
    margin-bottom: 12px !important;
  }

  .login-password-wrapper {
    padding-top: 20px;
  }

  .login-btn-wrapper {
    padding: 21.6px 0px 43px;
  }

  .login-button {
    font-size: 12px !important;
    padding: 11.8px 16px 11.5px !important;
  }

  .or-text-wrapper hr {
    width: 45%;
  }

  .continue-btns-wrapper {
    padding: 32px 0px 14px;
  }

  .continue-btn button > img {
    margin-right: 9px;
  }

  .error-alert > div:first-child {
    bottom: 30px;
    left: 24px;
    right: 24px;
    text-align: center;
  }

  .error-alert > div > div:first-child > div {
    line-height: 16px;
  }
}

@media screen and (max-width: 599px) {
  .login-bg-wrapper img {
    height: auto;
    max-height: 100px;
  }
}
