.input-field {
  border: 1px solid var(--primary-grey-color);
  border-radius: 4px;
  background: transparent;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  max-height: 48px !important;
  line-height: 16px;
  padding: 15px 16px;
  width: 100%;
  max-width: -webkit-fill-available;
}

.input-field:focus {
  border-color: var(--secondary-grey-color);
  background: transparent;
  color: var(--secondary-grey-color);
  outline: none;
}

.input-filled {
  border-color: #ffffff;
}

.input-field:focus.input-error,
.input-error {
  border-color: var(--error-color);
}

input:focus-visible {
  outline: none !important;
}
