.table-search-input {
  border-radius: 4px;
  background: var(--navy-blue-color);
  min-width: 327px !important;
}

.table-search-input input:focus {
  color: var(--secondary-grey-color);
}

.table-search-input * {
  border: none !important;
}

.search-input-error {
  border: 1px solid #cd1c35 !important;
}

.table-search-input input {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding: 11.4px 16px;
  border-radius: 8px;
}

.input-close-icon {
  cursor: pointer !important;
  pointer-events: auto !important;
  display: none;
}

.input-search-icon {
  display: block;
}

.search-icon-filled .input-close-icon {
  display: none;
}

.search-icon-filled .input-search-icon {
  display: block;
}

.search-icon-filled {
  filter: invert(100%) sepia(56%) saturate(0%) hue-rotate(272deg)
    brightness(200%) contrast(100%);
}

.table-search-input .search-icon-filled,
.table-search-input input:focus + .search-icon-filled {
  filter: invert(40%) sepia(0%) saturate(958%) hue-rotate(21deg)
    brightness(135%) contrast(100%);
}

.table-search-input .search-icon-filled .input-close-icon {
  display: block;
}

.table-search-input .search-icon-filled .input-search-icon {
  display: none;
}

.input-close-icon:hover {
  display: block;
}

.input-close-icon:hover + .input-search-icon {
  display: none;
}

.empty-input .input-close-icon {
  display: none;
}

@media screen and (max-width: 599px) {
  .table-search-input {
    width: 100%;
  }
}
