.empty-data-table-wrapper {
  height: calc(100% - 18px);
}

.empty-data-msg-wrapper {
  text-align: center;
  white-space: pre;
}

.empty-data-msg-wrapper h3,
.empty-data-msg-wrapper p {
  margin: 0px;
}

.empty-data-msg-wrapper h3 {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: center;
  padding-bottom: 20px;
}

.empty-data-msg-wrapper p {
  color: #ffffff4d;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  padding-bottom: 32px;
}

.empty-data-msg-wrapper button {
  display: flex;
  align-items: center;
  margin: auto;
  height: 40px;
  width: 120px;

  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.empty-data-msg-wrapper button::before {
  background-color: var(--navy-blue-color);
}
