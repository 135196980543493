.top-tracks-wrapper {
  padding: 0px 16px 18px 0px;
}

.tracks-back-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 43px 33px 28px 25px;
}

.tracks-heading {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
}

.recognised-tracks-text {
  color: #ffffff4d;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin: 11px 0 0;
}

.track-wrapper {
  padding-right: 38px;
}

.tracks-container {
  height: 350px;
  overflow-y: auto;
}

.track-wrapper:hover {
  background: #161a26;
}

.track-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 32px;
}

.track-img-text {
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
}

.track-img-text span,
.track-img-text h6,
.tracks-count {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0px;
}

.track-img-text span {
  color: var(--primary-grey-color);
}

.track-img-text img {
  object-fit: contain;
  border-radius: 4px;
  width: 48px;
  height: 48px;
  margin: 0 28px 0px 19px;
}

.track-img-text h6 {
  cursor: pointer;
  color: var(--secondary-grey-color);
  text-decoration: underline;
  margin-bottom: 6px;
}

.track-img-text p {
  color: var(--primary-grey-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
}

.tracks-count {
  color: var(--secondary-grey-color);
}

.no-tracks-wrapper {
  padding: 60px;
  text-align: center;
}

.no-tracks-wrapper p {
  color: #ffffffb3;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .tracks-back-wrapper {
    padding-right: 28px;
  }
}

@media screen and (max-width: 599px) {
  .top-tracks-wrapper {
    padding-right: 0px;
    padding-bottom: 40px;
  }

  .tracks-back-wrapper {
    padding-top: 30px;
    padding-right: 14.5px;
  }

  .track-wrapper {
    padding-right: 19.5px;
  }

  .tracks-heading {
    font-size: 14px;
  }

  .recognised-tracks-text {
    margin-top: 8px;
  }

  .tracks-container {
    height: 270px;
  }

  .track-img-text img {
    margin: 0px 12px 0px 10px;
  }

  .track-img-text span,
  .track-img-text h6,
  .tracks-count {
    font-size: 14px;
    line-height: 18px;
  }

  .track-img-text h6 {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .track-img-text p {
    font-size: 12px;
    line-height: 14px;
  }

  .tracks-count {
    font-size: 12px;
    line-height: 16px;
  }
}

@media screen and (max-width: 375px) {
  .track-img-text h6 {
    max-width: 135px;
  }
}
