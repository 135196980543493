.reports-table-wrapper table {
  height: -webkit-fill-available;
  height: -moz-available;
}

.reports-table-wrapper table tfoot {
  height: 100%;
}

.reports-table-wrapper table tfoot tr td {
  vertical-align: bottom;
}

.reports_table > td:nth-child(1) {
  width: 140px;
  max-width: 140px;
}

.reports_table > td:nth-child(2) {
  width: 93px;
  max-width: 93px;
}

.reports_table > td:nth-child(3) {
  width: 60px;
  max-width: 60px;
}

.reports_table > td:nth-child(4) {
  width: 110px;
  max-width: 110px;
}

@media screen and (max-width: 1199px) {
  .reports-table-wrapper {
    height: 540px !important;
  }
}

@media screen and (max-width: 599px) {
  .reports-table-wrapper {
    height: 390px;
  }
}
