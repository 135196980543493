.verify-head-wrapper {
  padding-top: 3px;
  padding-bottom: 20px;
}

.table-head-d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.d-flex-gap {
  gap: 16px;
}

.verify-btns-wrapper > button {
  color: #ffffffcc !important;
  font-weight: 400;
  padding: 12px 34px;
  font-size: 12px;
}

.verify-btns-wrapper > button,
.verify-btns-wrapper > button::before,
.verify-btns-wrapper .button-overlay {
  border-radius: 4px;
}

.verify-checkbox {
  color: var(--primary-grey-color) !important;
  padding: 0px !important;
  width: 19px !important;
  height: 19px !important;
}

.verify-checkbox.Mui-checked {
  color: #d9d9d9 !important;
  background: var(--primary-grey-color) !important;
  border-radius: 4px !important;
}

.users-wrapper tbody tr {
  cursor: pointer;
}

.users-wrapper tbody tr > td:first-child {
  width: 30px;
}

.users-wrapper tbody tr > td:nth-child(2) {
  width: 140px;
  max-width: 140px;
}

.users-wrapper tbody tr > td:nth-child(3) {
  width: 150px;
  max-width: 150px;
}

.users-wrapper tbody tr > td:nth-child(4) {
  width: 150px;
  max-width: 150px;
}

.users-wrapper tbody tr > td:nth-child(5) {
  width: 50px;
  max-width: 50px;
}

.users-wrapper tbody tr > td:nth-child(6) {
  width: 50px;
  max-width: 50px;
}

.users-wrapper tbody tr > td:nth-child(7) {
  width: 220px;
  max-width: 220px;
}

.users-wrapper tbody tr > td:nth-child(8) {
  width: 150px;
  max-width: 150px;
}

.table-wrapper input:-webkit-autofill {
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #1b2130 inset;
  transition: background-color 5000s ease-in-out 0s;
  border-radius: 0px;
}

.last-updated-by .show-attributes p {
  text-decoration: none;
}

@media screen and (max-width: 599px) {
  .d-flex-verify {
    flex-direction: column;
    width: 100%;
  }

  .verify-table-container {
    height: 378px;
  }

  .verify-head-wrapper {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }

  .verify-btns-wrapper {
    width: -webkit-fill-available;
    width: -moz-available;
  }

  .verify-btns-wrapper button {
    width: 100% !important;
  }
}
