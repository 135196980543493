.confirmation-modal .MuiPaper-root {
  background: var(--navy-blue-color);
  border-radius: 8px;
  box-shadow: none;
  padding: 46px 44px 30px;
  margin: 0px;
}

.account-type-modal .MuiPaper-root {
  padding: 50px 40px 40px;
}

.account-type-modal .MuiDialogContent-root {
  padding-left: 11px;
  padding-right: 0px;
}

.confirmation-modal > div:first-child {
  background: #0f0f0fb3 !important;
  filter: blur(16px);
}

.confirmation-modal h2,
.modal-description {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;
  text-align: center;
  margin: 0px;
}

.confirmation-modal h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  padding: 0px;
}

.modal-description {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  padding-top: 12px;
  white-space: pre;
}

.confirmation-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 41px 13px 0px !important;
}

.reverse-btns-order {
  flex-direction: column-reverse;
}

.modal-btns {
  font-weight: 500;
  padding: 14px 16px 15px !important;
}

.modal-outlined-btn::before {
  background-color: var(--navy-blue-color);
}

.modal-spinner-wrapper {
  padding: 20px 130px 0px;
}

.modal-spinner-wrapper svg {
  width: 80px;
  height: 80px;
}

.modal-wait-text {
  color: #ffffff !important;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;
  margin: 0px;
  padding-top: 38px;
}

.verification-updated-wrapper {
  /* padding: 16px 70px 12px; */
  padding-left: 70px;
  padding-right: 70px;
  text-align: center;
}

.verification-updated-text {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;
  margin: 0px;
  padding-top: 44px;
}

@media screen and (max-width: 599px) {
  .confirmation-modal .MuiPaper-root {
    padding-top: 40px;
    padding-bottom: 10px;
  }

  .account-type-modal .MuiPaper-root {
    padding: 40px 20px 16px;
  }

  .confirmation-modal h2 {
    font-size: 16px;
    line-height: 20px;
  }

  .modal-description {
    font-size: 12px;
    padding-top: 24px;
  }

  .modal-btns {
    border-radius: 4px;
    font-size: 12px;
    padding: 12px 16px !important;
  }

  .modal-btns::before {
    border-radius: 4px;
  }

  .verification-updated-wrapper {
    padding: 10px 50px 32px;
  }

  .verification-updated-wrapper img {
    width: 65px;
    height: 65px;
  }

  .verification-updated-wrapper h5 {
    font-size: 16px;
    line-height: 20px;
    padding-top: 38px;
  }
}
