.accounts-table-wrapper table {
  height: -webkit-fill-available;
  height: -moz-available;
}

.accounts-table-wrapper table tfoot {
  height: 100%;
}

.accounts-table-wrapper table tfoot tr td {
  vertical-align: bottom;
}

.accounts-table > td:nth-child(1) {
  width: 110px;
  max-width: 110px;
}

.accounts-table > td:nth-child(2) > div:first-child {
  width: 93px;
  max-width: 93px;
}

.accounts-table > td:nth-child(3) {
  width: 26px;
  padding: 0px 19px;
}

.accounts-table > td:nth-child(4) {
  width: 106px;
  max-width: 106px;
}

.accounts-table > td:nth-child(5) {
  width: 60px;
  max-width: 60px;
}

.accounts-table > td:nth-child(6) {
  width: 200px;
  max-width: 200px;
}

@media screen and (min-width: 600px) {
  .accounts-table-wrapper {
    padding-top: 12px;
  }
}

@media screen and (max-width: 1199px) {
  .accounts-table-wrapper {
    height: 540px !important;
    max-height: 550px !important;
  }
}

@media screen and (max-width: 599px) {
  .accounts-table-wrapper {
    height: 410px;
  }
}
